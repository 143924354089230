import * as React from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import MapIcon from "@mui/icons-material/Map";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import VisibilityIcon from "@mui/icons-material/Edit";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  fabClasses,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputBase from "@mui/material/InputBase";
import L from "leaflet";
import redPinIcon from "../../../Assets/images/pin.png";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { useEffect } from "react";
import { fetchViewCategory } from "../../../Redux/Slice/Category/ViewCategorySlice";
import { fetchViewDealer } from "../../../Redux/Slice/Dealer/ViewDealerSlice";
import { fetchViewSubdealer } from "../../../Redux/Slice/Subdealer/ViewSubdealerSlice";
import { fetchViewClient } from "../../../Redux/Slice/Client/ViewClientSlice";
import { fetchViewAdminPjp } from "../../../Redux/Slice/Admin/Pjp/ViewAdminPjpSlice";
import Topbar from "../../global/Topbar";
import { addAdminPjp } from "../../../Redux/Slice/Admin/Pjp/AddAdminPjpSlice";
import { fetchViewUser } from "../../../Redux/Slice/Users/ViewUserSlice";
import { updateAdminPjp } from "../../../Redux/Slice/Admin/Pjp/UpdateAdminPjpSlice";
import ExcelDownload from "../../Excel/ExcelDownload";
import { fetchViewAdminClient } from "../../../Redux/Slice/Admin/Clients/ViewAdminClientsSlice";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function PjpAdmin() {
  const [searchTerm, setSearchTerm] = React.useState("");
  const dispatch = useDispatch();
  const AllPjp = useSelector(
    (state) => state.ViewAdminPjp.ViewAdminPjpData?.data
  );

  const loading = useSelector((state) => state.ViewAdminPjp.isLoader);
  const error = useSelector((state) => state.ViewAdminPjp.isError);
  const [selectedEmpCodes, setSelectedEmpCodes] = React.useState([]);

  const locationOnIcon = new L.Icon({
    iconUrl: redPinIcon,
    iconSize: [25, 30],
    iconAnchor: [15, 30],
    popupAnchor: [0, -30],
  });
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handleSearch = (event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const filteredData = AllPjp?.filter(
    (client) =>
      (selectedEmpCodes.length === 0 ||
        selectedEmpCodes.includes(client?.pjp?.emp_code)) &&
      (client?.pjp?.concerned_person.toLowerCase().includes(searchTerm) ||
        client?.pjp?.firm_name.toLowerCase().includes(searchTerm))
  );
  const [selectedDate, setSelectedDate] = React.useState(null);
  // Function to handle date selection change
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const filteredDataByDate = selectedDate
    ? filteredData?.filter((entry) => {
        // Extract the day, month, and year from pjp.date
        const [pjpDay, pjpMonth, pjpYear] = entry?.pjp?.date.split("-");
        // Extract the day, month, and year from the selectedDate
        const [selectedDay, selectedMonth, selectedYear] =
          selectedDate.split("-");
        // Check if the pjp.date matches the selectedDate
        return (
          pjpDay === selectedDay &&
          pjpMonth === selectedMonth &&
          pjpYear === selectedYear
        );
      })
    : filteredData;

  const slicedData = filteredDataByDate?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [openMapModal, setOpenMapModal] = React.useState(false);
  const [mapLocation, setMapLocation] = React.useState({
    latitude: 0,
    longitude: 0,
  });

  const handleOpenMapModal = (userInfo) => {
    const latitude = parseFloat(userInfo?.latitude);
    const longitude = parseFloat(userInfo?.longitude);

    if (isNaN(latitude) || isNaN(longitude)) {
      toast.error("Latitude and/or Longitude not available for this Visit.");
      return;
    }

    setMapLocation({
      latitude: latitude,
      longitude: longitude,
    });
    setOpenMapModal(true);
  };

  const handleCloseMapModal = () => {
    setOpenMapModal(false);
  };
  const CategoryData = useSelector(
    (state) => state.ViewCategory.ViewCategoryData
  );
  const EmpData = useSelector((state) => state.ViewUser.ViewUserData?.data);

  const dealerData = useSelector(
    (state) => state.ViewDealer.ViewDealerData?.data
  );

  const SubdealerData = useSelector(
    (state) => state.ViewSubdealer.ViewSubdealerData?.data
  );

  const ClientsData = useSelector(
    (state) => state.ViewAdminClients.ViewAdminClientData?.data
  );

  React.useEffect(() => {
    dispatch(fetchViewAdminPjp());
    dispatch(fetchViewCategory());
    dispatch(fetchViewDealer());
    dispatch(fetchViewSubdealer());
    dispatch(fetchViewAdminClient());
    dispatch(fetchViewUser());
  }, [dispatch]);

  //=============================================== add Pjp

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [category, setCategory] = useState("");
  const [empCode, setEmpCode] = useState("");
  const [list, setList] = useState("");
  const [firm_name, setFirmName] = useState("");
  const [concerned_person, setConcernedPerson] = useState("");
  const [firm_id, setFromId] = useState("");
  const [listOptions, setListOptions] = useState([]);
  const [downloadExcel, setdownloadExcel] = useState(false);
  const [excelData, setexcelData] = useState();

  const [formData, setFormData] = useState({
    category: "",
    firm_name: "",
    concerned_person: "",
    date: "",
    remarks: "",
    server_time: "",
    firm_id: "",
    empCode: "",
  });
  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    const milliseconds = String(now.getMilliseconds()).padStart(6, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  };
  const [server_time] = useState(getCurrentDateTime());
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      category,
      firm_name,
      concerned_person,
      firm_id,
      server_time,
      empCode,
    }));
  }, [category, firm_name, concerned_person, firm_id, server_time, empCode]);
  const [formErrors, setFormErrors] = useState({
    category: "",
    firm_name: "",
    concerned_person: "",
    date: "",
    remarks: "",
    server_time: "",
    empCode: "",
  });

  const validateForm = () => {
    const errors = {};

    if (!formData.category.trim()) {
      errors.category = "Category is required";
    }
    if (!formData.firm_name.trim()) {
      errors.firm_name = "Firm Name is required";
    }
    if (!formData.concerned_person.trim()) {
      errors.concerned_person = "Concerned Person is required";
    }
    if (!formData.date.trim()) {
      errors.date = "Date is required";
    }
    if (!formData.empCode.trim()) {
      errors.empCode = "Assigned person is required";
    }
    return errors;
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleForm = () => {
    // If form submission is already in progress, prevent multiple submissions
    if (isSubmitting) {
      return;
    }

    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
      // Set errors and prevent form submission
      setFormErrors(errors);
      return;
    }

    // Set form submission in progress
    setIsSubmitting(true);

    dispatch(addAdminPjp(formData))
      .then(() => {
        setFormData({
          category: "",
          firm_name: "",
          concerned_person: "",
          date: "",
          remarks: "",
          server_time: "",
          empCode: "",
        });
        setFormErrors({});

        // Reload data after successful addition
        dispatch(fetchViewAdminPjp());

        // Close the modal
        handleCloseModal();
      })
      .catch((error) => {
        console.error("Error adding Architect:", error);
        toast.error("Error adding Architect. Please try again.");
      })
      .finally(() => {
        // Reset form submission status
        setIsSubmitting(false);
      });
  };

  const handleChange = (value, type) => {
    if (type === "category") {
      setCategory(value);
      setList("");

      // Reset firm name and concerned person
      setFirmName("");
      setConcernedPerson("");

      // Reset form data for firm-related fields
      setFormData((prevState) => ({
        ...prevState,
        firm_name: "",
        firm_id: "",
      }));
    } else if (type === "list") {
      setFirmName(value);
      setList(value);

      // Find the selected entity in the data
      let selectedEntity;

      if (category === "Dealer") {
        selectedEntity = dealerData.find(
          (dealer) => dealer.dealerName === value
        );
      } else if (category === "SubDealer") {
        selectedEntity = SubdealerData.find(
          (subdealer) => subdealer.name === value
        );
      } else {
        selectedEntity = ClientsData.find(
          (client) => client.clientName === value
        );
      }

      // Set the concerned_person value based on the selected entity
      if (selectedEntity) {
        setConcernedPerson(selectedEntity.concered_person);
      } else {
        setConcernedPerson(""); // If entity not found, reset concerned_person
      }
      // Set the firm_id value based on the selected entity
      if (selectedEntity) {
        setFromId(selectedEntity.id);
      } else {
        setFromId(""); // If entity not found, reset concerned_person
      }
    }
  };

  //  ============================================== update=============================================//
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [selectedPjp, setSelectedPjp] = React.useState(null);
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [selectedEmpCode, setSelectedEmpCode] = React.useState(null);
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };
  const [editPjpData, setEditPjpData] = React.useState({
    id: "",
    category: "",
    firm_name: "",
    concerned_person: "",
    date: "",
    remarks: "",
    server_time: "",
    firm_id: "",
    empCode: "",
  });

  const handleEditModalChange = (value, type, field) => {
    if (type === "category") {
      // Update the category state with the new value
      setCategory(value);

      // Reset other related states
      setList("");
      setFirmName("");
      setFromId("");

      // Clear the firm name dropdown options
      setListOptions([]);

      // Update the editPjpData state with the new category value
      setEditPjpData((prevData) => ({
        ...prevData,
        [field]: value,
        server_time: getCurrentDateTime(), // Update server_time here
      }));
    } else if (type === "list") {
      // If the type is list, update the firm name state
      setFirmName(value);
      setList(value);

      // Find the selected entity in the data based on the category
      let selectedEntity;
      if (value && category) {
        if (category === "Dealer") {
          selectedEntity = dealerData.find(
            (dealer) => dealer.dealerName === value
          );
        } else if (category === "SubDealer") {
          selectedEntity = SubdealerData.find(
            (subdealer) => subdealer.name === value
          );
        } else {
          selectedEntity = ClientsData.find(
            (client) => client.clientName === value
          );
        }
      }

      // Update concerned person and firm ID based on the selected entity
      const newConcernedPerson = selectedEntity
        ? selectedEntity.concerned_person
        : "";
      const newFirmId = selectedEntity ? selectedEntity.id : "";

      // Update the editPjpData state with the new value
      setEditPjpData((prevData) => ({
        ...prevData,
        [field]: value,
        concerned_person: newConcernedPerson,
        firm_id: newFirmId,
        server_time: getCurrentDateTime(), // Update server_time here
      }));
    }
  };

  const handleEditPjp = () => {
    // Update server_time before dispatching the action
    const updatedEditPjpData = {
      ...editPjpData,
      server_time: getCurrentDateTime(),
    };

    dispatch(updateAdminPjp(updatedEditPjpData))
      .then(() => {
        // Reload data after successful update
        dispatch(fetchViewAdminPjp());
        // toast.success("Designation updated successfully!");

        // Close the edit modal
        handleCloseEditModal();
      })
      .catch((error) => {
        console.error("Error updating Pjp:", error);
        toast.error("Error updating Pjp. Please try again.");
      });
  };

  const handleOpenEditModal = (pjpData) => {
    if (pjpData) {
      setSelectedPjp(pjpData);
      setEditPjpData({
        id: pjpData.id,
        category: pjpData.category,
        firm_name: pjpData.firm_name,
        concerned_person: pjpData.concerned_person,
        date: pjpData.date,
        remarks: pjpData.remarks,
        firm_id: pjpData.firm_id,
        empCode: pjpData.emp_code,
      });

      // Set default values for dropdowns based on existing data
      setSelectedCategory(pjpData.category);
      setSelectedEmpCode(pjpData.empCode);
      setCategory(pjpData.category);
      setEmpCode(pjpData.empCode); // Set the category state as well
    } else {
      setEditPjpData({
        id: "",
        category: "", // Initialize category as an empty string
        firm_name: "",
        concerned_person: "",
        date: "",
        remarks: "",
        firm_id: "",
        empCode: "",
      });
    }
    setOpenEditModal(true);
  };
  // ==============================================
  useEffect(() => {
    if (["Architect", "Contractor", "Competitor", "Other"].includes(category)) {
      dispatch(fetchViewClient());
    } else if (category === "Dealer") {
      dispatch(fetchViewDealer());
    } else if (category === "SubDealer") {
      dispatch(fetchViewSubdealer());
    }
  }, [category, dispatch]);

  useEffect(() => {
    if (category === "Architect") {
      const architectClients = ClientsData.filter(
        (client) => client.categoery_id === "5"
      );
      setListOptions(
        architectClients.map((clientItem) => clientItem.clientName)
      );
    } else if (category === "Builder") {
      const builderClients = ClientsData.filter(
        (client) => client.categoery_id === "6"
      );
      setListOptions(builderClients.map((clientItem) => clientItem.clientName));
    } else if (category === "Contractor") {
      const contractorClients = ClientsData.filter(
        (client) => client.categoery_id === "7"
      );
      setListOptions(
        contractorClients.map((clientItem) => clientItem.clientName)
      );
    } else if (category === "Competitor") {
      const competitorClients = ClientsData.filter(
        (client) => client.categoery_id === "8"
      );
      setListOptions(
        competitorClients.map((clientItem) => clientItem.clientName)
      );
    } else if (category === "Other") {
      const otherClients = ClientsData.filter(
        (client) => client.categoery_id === "10"
      );
      setListOptions(otherClients.map((clientItem) => clientItem.clientName));
    } else if (category === "Dealer") {
      const dealerNames = dealerData.map((dealerItem) => dealerItem.dealerName);
      setListOptions(dealerNames);
    } else if (category === "SubDealer") {
      const subdealerNames = SubdealerData.map(
        (subdealerItem) => subdealerItem.name
      );
      setListOptions(subdealerNames);
    }
  }, [category, ClientsData, dealerData, SubdealerData]);

  const donloadexcel = () => {
    setdownloadExcel(true);
    const table = document.getElementById("exportTable");
    // console.log(table);
    // // Get the table element
    // const wb = XLSX.utils.table_to_book(table); // Convert the table to a workbook
    // console.log(wb);
    // XLSX.writeFile(wb, "data.xlsx");
    setexcelData(table);
  };

  const disabledownload = () => {
    setdownloadExcel(false);
  };
  // ==================date===================//
  const [date, setDate] = useState(getCurrentDate());
  function getCurrentDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const handleEmpCodeChange = (event, newValue) => {
    setSelectedEmpCodes(newValue);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString(); // Use the full year
    return `${day}/${month}/${year}`;
  }
  const sortedListOptions = listOptions.sort((a, b) => a.localeCompare(b));
  return (
    <>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle
          sx={{ background: "linear-gradient(to right, #fff, #bfbfbf)" }}
        >
          Add Pjp
        </DialogTitle>
        <div
          style={{
            background:
              "url('https://img.freepik.com/premium-vector/flat-polygonal-background_23-2148918677.jpg?w=740')",
            backgroundSize: "cover",
          }}
        >
          <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <h5>Category*</h5>
                  <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="role_id">Category</InputLabel>
                    <Select
                      label="category"
                      name="category"
                      id="category"
                      value={category}
                      onChange={(event) =>
                        handleChange(event.target.value, "category")
                      } // Updated line
                      error={!!formErrors.category}
                      helperText={formErrors.category}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    >
                      {CategoryData && CategoryData.data ? (
                        CategoryData.data.map((category) => (
                          <MenuItem key={category.id} value={category.name}>
                            {category.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="">Loading...</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <h5 style={{ marginBottom: "35px" }}>Firm Name*</h5>
                  <Autocomplete
                    fullWidth
                    margin="normal"
                    options={sortedListOptions}
                    value={firm_name}
                    onChange={(event, value) => handleChange(value, "list")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Name"
                        error={!!formErrors.firm_name}
                        helperText={formErrors.firm_name}
                        sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <h5>Concerned Person*</h5>
                  <TextField
                    label="Concerned Person"
                    fullWidth
                    multiline
                    margin="normal"
                    name="concerned_person"
                    id="concerned_person"
                    value={concerned_person}
                    onChange={handleChange}
                    error={!!formErrors.concerned_person}
                    helperText={formErrors.concerned_person}
                    sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <h5>Date*</h5>
                  <TextField
                    label="Date"
                    type="date"
                    fullWidth
                    margin="normal"
                    name="date"
                    error={!!formErrors.date}
                    helperText={formErrors.date}
                    id="date"
                    // value={date}
                    onChange={(e) => {
                      setDate(e.target.value); // Update the date value
                      setFormData({
                        ...formData,
                        date: e.target.value, // Update the date in the formData state
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: getCurrentDate(), // Set min attribute to the current date
                    }}
                  />
                </Grid>{" "}
                <Grid item xs={12}>
                  <h5>Assigned To*</h5>
                  <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="role_id">Assigned To</InputLabel>
                    <Select
                      label="empCode"
                      name="empCode"
                      id="empCode"
                      value={formData.empCode}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          empCode: e.target.value, // Update the date in the formData state
                        });
                      }}
                      error={!!formErrors.empCode}
                      helperText={formErrors.empCode}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    >
                      {EmpData && EmpData ? (
                        EmpData.filter((emp) => emp.is_active === "1").map(
                          (emp) => (
                            <MenuItem key={emp.id} value={emp.empCode}>
                              {emp.name} | Emp Code: {emp.empCode}
                            </MenuItem>
                          )
                        )
                      ) : (
                        <MenuItem value="">Loading...</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <h5>Remarks</h5>
                  <TextField
                    label="Remarks"
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                    name="remarks"
                    id="remarks"
                    value={formData.remarks}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        remarks: e.target.value, // Update the date in the formData state
                      });
                    }}
                    error={!!formErrors.remarks}
                    helperText={formErrors.remarks}
                    sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </div>
        </div>

        <DialogActions
          sx={{ background: "linear-gradient(to right, #bfbfbf, #fff)" }}
        >
          <Button
            onClick={handleCloseModal}
            color="secondary"
            style={{
              background: "#fff",
              height: "100%",
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleForm}
            style={{
              background: "linear-gradient(to right, #2d0031, #4d0054)",
              height: "100%",
              color: "white",
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Topbar />
      <div style={{ padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "5px",
            background: "linear-gradient(to right, #4d0054, #91009e)",
            border: "1px solid #ddd",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0)",
            borderRadius: "5px",
            padding: "10px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: "#fff" }}
              startIcon={<AddIcon />}
              onClick={handleOpenModal}
            >
              Add Pjp
            </Button>
          </div>
          <h4 style={{ color: "#ffffff", margin: "0 auto" }}>Pjp Details</h4>
          <div style={{ display: "flex", alignItems: "center" }}>
            {" "}
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={Array.from(
                new Set(
                  AllPjp
                    ? AllPjp.filter(
                        (client) => client.pjp && client?.pjp?.emp_code
                      ) // Filter out clients without user data or empCode
                        .map((client) => client?.pjp?.emp_code)
                    : []
                )
              )} // Provide an empty array if clientData is undefined
              disableCloseOnSelect
              onChange={handleEmpCodeChange}
              value={selectedEmpCodes}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} />
                  {option}
                </li>
              )}
              style={{ height: "33px" }}
              renderInput={(params) => (
                <Paper
                  component="form"
                  style={{ padding: "6px" }}
                  ref={params.InputProps.ref}
                >
                  <input
                    {...params.inputProps}
                    placeholder="Select User"
                    style={{ width: "100px" }}
                  />
                </Paper>
              )}
            />{" "}
            <input
              type="date"
              label="Filter by Date"
              value={selectedDate}
              onChange={(e) => handleDateChange(e.target.value)}
              style={{
                marginLeft: "10px",
                borderRadius: "5px",
                padding: "8px",
              }}
            />
            <InputBase
              sx={{ ml: 2, width: "150px" }}
              placeholder="Search by Firm or Concerned Person"
              onChange={handleSearch}
              style={{
                color: "#000",
                paddingLeft: "20px",
                padding: "3px",
                paddingRight: "5px",
                borderRadius: "5px",
                backgroundColor: "#fff",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                outline: "none",
                transition: "background-color 0.3s, box-shadow 0.3s",
              }}
            />
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: "#fff", marginLeft: "10px" }}
              onClick={donloadexcel}
            >
              Download
            </Button>
          </div>
        </div>

        <ToastContainer position="bottom-right" autoClose={3000} />
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error.message}</div>
        ) : AllPjp && AllPjp.length > 0 ? (
          <React.Fragment>
            <TableContainer component={Paper}>
              <Table aria-label="customized table" id="exportTable">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Sl. No.
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Emp Code
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Emp Name
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Category
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Firm Name
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Concerned Person
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Remarks
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      GeoTagged
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Date
                    </StyledTableCell>{" "}
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Reschedule Date
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Action
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Map
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {slicedData.map((entry, index) => {
                    const dealer = entry.pjp || {}; // Access dealer object
                    const userInfo = entry.client || {}; // Access user_info object
                    const empInfo = entry.user || {};
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell>
                          {index + 1 + page * rowsPerPage}
                        </StyledTableCell>
                        <StyledTableCell>{empInfo.empCode}</StyledTableCell>
                        <StyledTableCell>{empInfo.name}</StyledTableCell>
                        <StyledTableCell>{dealer.category}</StyledTableCell>
                        <StyledTableCell>{dealer.firm_name}</StyledTableCell>
                        <StyledTableCell>
                          {dealer.concerned_person}
                        </StyledTableCell>
                        <StyledTableCell>{dealer.remarks}</StyledTableCell>
                        <StyledTableCell>
                          {userInfo.latitude && userInfo.longitude
                            ? "Yes"
                            : "No"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {formatDate(dealer.date)}
                        </StyledTableCell>
                        <StyledTableCell>
                          <StyledTableCell>
                            {dealer.schedule_date
                              ? dealer.schedule_date.split(",").map((date) => {
                                  const parts = date.split("-");
                                  const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
                                  return (
                                    <React.Fragment key={date}>
                                      {formattedDate}
                                      <br />
                                    </React.Fragment>
                                  );
                                })
                              : ""}
                          </StyledTableCell>
                        </StyledTableCell>
                        <StyledTableCell>
                          <IconButton color="secondary">
                            <VisibilityIcon
                              onClick={() => handleOpenEditModal(dealer)}
                            />
                          </IconButton>
                        </StyledTableCell>
                        <StyledTableCell>
                          {userInfo.latitude && userInfo.longitude ? (
                            <IconButton
                              color="secondary"
                              onClick={() => handleOpenMapModal(userInfo)}
                            >
                              <MapIcon />
                            </IconButton>
                          ) : null}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, filteredDataByDate.length]}
              component="div"
              count={filteredDataByDate.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Rows per page"
            />
          </React.Fragment>
        ) : (
          <div style={{ padding: "20px" }}>No User available</div>
        )}

        <Dialog open={openMapModal} onClose={handleCloseMapModal}>
          <DialogTitle>Location on Map</DialogTitle>
          <DialogContent>
            <MapContainer
              center={[mapLocation.latitude, mapLocation.longitude]}
              zoom={13}
              style={{ height: "400px", width: "400px" }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Marker
                position={[mapLocation.latitude, mapLocation.longitude]}
                icon={locationOnIcon}
              />
            </MapContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMapModal} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Dialog open={openEditModal} onClose={handleCloseModal}>
        <DialogTitle
          sx={{ background: "linear-gradient(to right, #fff, #bfbfbf)" }}
        >
          Update Pjp
        </DialogTitle>
        <div
          style={{
            background:
              "url('https://img.freepik.com/premium-vector/flat-polygonal-background_23-2148918677.jpg?w=740')",
            backgroundSize: "cover",
          }}
        >
          <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
            <DialogContent>
              <Grid container spacing={2}>
                <input
                  type="hidden"
                  id="eid"
                  value={editPjpData ? editPjpData.id : ""}
                />
                <Grid item xs={6}>
                  <h5 style={{ marginBottom: "35px" }}>Category*</h5>
                  <Select
                    fullWidth
                    margin="normal"
                    value={category} // Ensure value is set correctly
                    onChange={(event) =>
                      handleEditModalChange(event.target.value, "category")
                    }
                    sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                  >
                    <MenuItem value="" disabled>
                      Select Category
                    </MenuItem>
                    {CategoryData && CategoryData.data ? (
                      CategoryData.data.map((category) => (
                        <MenuItem key={category.id} value={category.name}>
                          {category.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">Loading...</MenuItem>
                    )}
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <h5 style={{ marginBottom: "35px" }}>Firm Name*</h5>

                  <Autocomplete
                    fullWidth
                    margin="normal"
                    options={sortedListOptions}
                    value={editPjpData.firm_name}
                    onChange={(e) =>
                      handleEditModalChange("firm_name", e.target.value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Name"
                        error={!!formErrors.firm_name}
                        helperText={formErrors.firm_name}
                        sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <h5>Concerned Person*</h5>
                  <TextField
                    label="Concerned Person"
                    fullWidth
                    multiline
                    margin="normal"
                    name="concerned_person"
                    id="concerned_person"
                    // defaultValue={editPjpData ? editPjpData.concerned_person : ""}
                    value={editPjpData.concerned_person}
                    onChange={(e) =>
                      handleEditModalChange("concerned_person", e.target.value)
                    }
                    error={!!formErrors.concerned_person}
                    helperText={formErrors.concerned_person}
                    sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <h5>Date*</h5>
                  <TextField
                    label="Date"
                    type="date"
                    fullWidth
                    margin="normal"
                    name="date"
                    error={!!formErrors.date}
                    helperText={formErrors.date}
                    id="date"
                    defaultValue={editPjpData ? editPjpData.date : ""}
                    sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    onChange={(e) => {
                      setDate(e.target.value); // Update the date value
                      setEditPjpData({
                        ...editPjpData,
                        date: e.target.value, // Update the date in the formData state
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: getCurrentDate(), // Set min attribute to the current date
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <h5>Assign To*</h5>
                  <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="role_id">Select Name</InputLabel>
                    <Select
                      label="empCode"
                      name="empCode"
                      id="empCode"
                      value={editPjpData.empCode} // Set the value to the corresponding state variable
                      onChange={(e) => {
                        setEditPjpData({
                          ...editPjpData,
                          empCode: e.target.value,
                        });
                      }}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    >
                      {EmpData && EmpData ? (
                        EmpData.filter((emp) => emp.is_active === "1").map(
                          (emp) => (
                            <MenuItem key={emp.id} value={emp.empCode}>
                              {emp.name} | Emp Code: {emp.empCode}
                            </MenuItem>
                          )
                        )
                      ) : (
                        <MenuItem value="">Loading...</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <h5>Remarks</h5>
                  <TextField
                    label="Remarks"
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                    name="remarks"
                    id="remarks"
                    defaultValue={editPjpData ? editPjpData.remarks : ""}
                    onChange={(e) => {
                      setEditPjpData({
                        ...editPjpData,
                        remarks: e.target.value,
                      });
                    }}
                    error={!!formErrors.remarks}
                    helperText={formErrors.remarks}
                    sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </div>
        </div>

        <DialogActions
          sx={{ background: "linear-gradient(to right, #bfbfbf, #fff)" }}
        >
          <Button
            onClick={handleCloseEditModal}
            color="secondary"
            style={{
              background: "#fff",
              height: "100%",
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleEditPjp}
            style={{
              background: "linear-gradient(to right, #2d0031, #4d0054)",
              height: "100%",
              color: "white",
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ display: downloadExcel ? "block" : "none" }}>
        {downloadExcel ? (
          <ExcelDownload
            data={excelData}
            name="pjp-data"
            onsubmit={disabledownload}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
