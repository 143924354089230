import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { fetchViewAdminVisits } from "../../../Redux/Slice/Admin/Visits/ViewAdminVisitsSlice";
import Topbar from "../../global/Topbar";
import { styled } from "@mui/material/styles";
import MapIcon from "@mui/icons-material/Map";
import { ToastContainer, toast } from "react-toastify";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import ExcelDownload from "../../Excel/ExcelDownload";
import "react-toastify/dist/ReactToastify.css";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableRow,
  Paper,
  Autocomplete,
  Checkbox,
  TablePagination,
  IconButton,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import redPinIcon from "../../../Assets/images/pin.png";
import L from "leaflet";
import { imageUrl } from "../../../Redux/apiConfig";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const WeeklyVisits = () => {
  const dispatch = useDispatch();
  const Visits = useSelector(
    (state) => state.ViewAdminVisits.ViewAdminVisitsData?.data
  );
  const loading = useSelector((state) => state.ViewAdminVisits.isLoader);
  const error = useSelector((state) => state.ViewAdminVisits.isError);
  const [selectedWeek, setSelectedWeek] = useState(getCurrentWeek());
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());
  const [selectedYear, setSelectedYear] = useState(getCurrentYear());
  const [selectedEmpCodes, setSelectedEmpCodes] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [downloadExcel, setdownloadExcel] = useState(false);
  const [excelData, setexcelData] = useState();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const locationOnIcon = new L.Icon({
    iconUrl: redPinIcon,
    iconSize: [25, 30],
    iconAnchor: [15, 30],
    popupAnchor: [0, -30],
  });

  function getCurrentWeek() {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    return currentDay <= 7
      ? "1st week"
      : currentDay <= 15
      ? "2nd week"
      : currentDay <= 21
      ? "3rd week"
      : "4th week";
  }

  function getCurrentMonth() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    return currentMonth;
  }

  function getCurrentYear() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    return currentYear;
  }

  const filterVisitsByWeekAndMonthAndYear = (
    visits,
    selectedWeek,
    selectedMonth,
    selectedYear,
    fromDate,
    toDate
  ) => {
    if (!visits) return [];

    let startDate, endDate;

    if (fromDate && toDate) {
      // Set the start date to the beginning of the selected day
      startDate = new Date(fromDate);
      startDate.setHours(0, 0, 0, 0);

      // Set the end date to the end of the selected day
      endDate = new Date(toDate);
      endDate.setHours(23, 59, 59, 999); // Set to the last millisecond of the day
    } else {
      switch (selectedWeek) {
        case "1st week":
          startDate = new Date(selectedYear, selectedMonth - 1, 1);
          endDate = new Date(
            selectedYear,
            selectedMonth - 1,
            7,
            23,
            59,
            59,
            999
          );
          break;
        case "2nd week":
          startDate = new Date(selectedYear, selectedMonth - 1, 8);
          endDate = new Date(
            selectedYear,
            selectedMonth - 1,
            15,
            23,
            59,
            59,
            999
          );
          break;
        case "3rd week":
          startDate = new Date(selectedYear, selectedMonth - 1, 16);
          endDate = new Date(
            selectedYear,
            selectedMonth - 1,
            21,
            23,
            59,
            59,
            999
          );
          break;
        case "4th week":
          startDate = new Date(selectedYear, selectedMonth - 1, 22);
          endDate = new Date(
            selectedYear,
            selectedMonth - 1,
            getLastDateOfMonth(selectedMonth, selectedYear),
            23,
            59,
            59,
            999
          );
          break;
        default:
          break;
      }
    }

    // Filter visits within the specified date range
    return visits.filter((visit) => {
      const visitDate = new Date(visit.visits.created_at);
      return visitDate >= startDate && visitDate <= endDate;
    });
  };

  // Update the handleFromDateChange and handleToDateChange functions to update the state
  // when the user selects dates

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  // Function to get the last date of the month
  const getLastDateOfMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };

  const handleWeekChange = (event) => {
    setSelectedWeek(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleEmpCodesChange = (event, values) => {
    setSelectedEmpCodes(values);
  };

  useEffect(() => {
    dispatch(fetchViewAdminVisits());
  }, [dispatch]);

  const filteredVisits = filterVisitsByWeekAndMonthAndYear(
    Visits,
    selectedWeek,
    selectedMonth,
    selectedYear,
    fromDate,
    toDate
  );

  const filteredVisitsByEmpCode = filteredVisits.filter(
    (item) =>
      selectedEmpCodes.length === 0 ||
      selectedEmpCodes.includes(item.visits.emp_code)
  );

  const empCodeOptions = [
    ...new Set(filteredVisits.map((item) => item.visits.emp_code)),
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const calculatePositiveCall = () => {
    return filteredVisitsByEmpCode.reduce(
      (total, item) =>
        item.visits.order_recipt === "Yes" ||
        item.visits.payment_status === "Yes"
          ? total + 1
          : total,
      0
    );
  };

  const percentage =
    filteredVisitsByEmpCode.length !== 0
      ? (calculatePositiveCall() / filteredVisitsByEmpCode.length) * 100
      : 0;
  const difference = filteredVisitsByEmpCode.length - calculatePositiveCall();

  // Slice the data for pagination
  const slicedData = filteredVisitsByEmpCode?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Define a function to format the date
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString(); // Use the full year
    return `${day}/${month}/${year}`;
  }

  function formatTime(dateString) {
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  }

  const [openMapModal, setOpenMapModal] = React.useState(false);
  const [mapLocation, setMapLocation] = React.useState({
    latitude: 0,
    longitude: 0,
  });

  const handleOpenMapModal = (AllVisitsUser) => {
    const latitude = parseFloat(AllVisitsUser?.visits.visit_latitude);
    const longitude = parseFloat(AllVisitsUser?.visits.visit_longitude);

    if (isNaN(latitude) || isNaN(longitude)) {
      toast.error("Latitude and/or Longitude not available for this Visit.");
      return;
    }

    setMapLocation({
      latitude: latitude,
      longitude: longitude,
    });
    setOpenMapModal(true);
  };

  const handleCloseMapModal = () => {
    setOpenMapModal(false);
  };
  const donloadexcel = () => {
    setdownloadExcel(true);
    const table = document.getElementById("exportTable");
    setexcelData(table);
  };
  const disabledownload = () => {
    setdownloadExcel(false);
  };
  return (
    <>
      <Topbar />
      <div style={{ padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: "linear-gradient(to right, #4d0054, #91009e)",
            border: "1px solid #ddd",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0)",
            borderRadius: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#fff", marginRight: "10px" }}
            onClick={donloadexcel}
          >
            Download
          </Button>{" "}
          <div style={{ flex: "1", textAlign: "center" }}>
            <h4 style={{ color: "#ffffff" }}>Weekly Visits</h4>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={empCodeOptions}
              disableCloseOnSelect
              onChange={handleEmpCodesChange}
              value={selectedEmpCodes}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} />
                  {option}
                </li>
              )}
              style={{
                height: "30px",
                marginRight: "3px",
                marginBottom: "1px",
              }}
              renderInput={(params) => (
                <Paper
                  component="form"
                  style={{ padding: "5px" }}
                  ref={params.InputProps.ref}
                >
                  <input
                    {...params.inputProps}
                    style={{ width: "100px" }}
                    placeholder="Select User"
                  />
                </Paper>
              )}
            />{" "}
            <input
              type="date"
              label="Date From"
              style={{
                marginLeft: "10px",
                borderRadius: "5px",
                padding: "8px",
              }}
              value={fromDate}
              onChange={handleFromDateChange}
            />
            <input
              type="date"
              label="Date To"
              style={{
                marginLeft: "10px",
                borderRadius: "5px",
                padding: "8px",
              }}
              value={toDate}
              onChange={handleToDateChange}
            />
            <select
              style={{
                padding: "7px",
                margin: "0 3px",
                borderRadius: "5px",
              }}
              value={selectedWeek}
              onChange={handleWeekChange}
            >
              <option value="1st week">1st week</option>
              <option value="2nd week">2nd week</option>
              <option value="3rd week">3rd week</option>
              <option value="4th week">4th week</option>
            </select>
            <select
              style={{
                padding: "7px",
                margin: "0 3px",
                borderRadius: "5px",
              }}
              value={selectedMonth}
              onChange={handleMonthChange}
            >
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>{" "}
            <select
              style={{
                padding: "7px",
                margin: "0 3px",
                borderRadius: "5px",
              }}
              value={selectedYear}
              onChange={handleYearChange}
            >
              {Array.from(
                { length: 101 },
                (_, i) => getCurrentYear() - 100 + i
              ).map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        </div>

        <Grid container spacing={3} style={{ padding: "10px" }}>
          <Grid item xs={3}>
            <Card style={{ backgroundColor: "#f8ee1f" }}>
              <CardContent style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <Typography variant="h5" component="div">
                    Total Calls
                  </Typography>
                  <Typography variant="h2" component="div">
                    {filteredVisitsByEmpCode.length}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card style={{ backgroundColor: "#2aed39de" }}>
              <CardContent style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <Typography variant="h5" component="div">
                    Positive Calls
                  </Typography>
                  <Typography variant="h2" component="div">
                    {calculatePositiveCall()}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>{" "}
          <Grid item xs={3}>
            <Card style={{ backgroundColor: "#ff4242de" }}>
              <CardContent style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <Typography variant="h5" component="div">
                    Negative Calls
                  </Typography>
                  <Typography variant="h2" component="div">
                    {difference}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>{" "}
          <Grid item xs={3}>
            <Card style={{ backgroundColor: "#dc2bf4de" }}>
              <CardContent style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <Typography variant="h5" component="div">
                    Productivity
                  </Typography>
                  <Typography variant="h2" component="div">
                    {percentage.toFixed(2)}%
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error.message}</div>
        ) : Visits && Visits.length > 0 ? (
          <React.Fragment>
            <TableContainer component={Paper}>
              <Table aria-label="customized table" id="exportTable">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Sl. No.
                    </StyledTableCell>{" "}
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Emp Code
                    </StyledTableCell>{" "}
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Emp Name
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Category
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Firm Name
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Concerned Person
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Type
                    </StyledTableCell>
                    <div
                      style={{
                        backgroundColor: "#7b0087",
                        width: "200px",
                        height: "73px",
                        textAlign: "center",
                        justifyContent: "center",
                        color: "#fff",
                        position: "relative",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12px",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        Remarks
                      </span>
                    </div>
                    {/* <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Geotagged
                    </StyledTableCell> */}
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Distance
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Date
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Time
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Collection
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Order Received
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Images
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Map
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {slicedData.map((item, index) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell>
                          {index + 1 + page * rowsPerPage}
                        </StyledTableCell>{" "}
                        <StyledTableCell>
                          {item.visits.emp_code}
                        </StyledTableCell>{" "}
                        <StyledTableCell>
                          {item.visits.user_name?.name}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.visits.category}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.visits.firm_name}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.visits.concerned_person}
                        </StyledTableCell>
                        <StyledTableCell>{item.visits.type}</StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>
                          {item.visits.remarks}
                        </StyledTableCell>
                        {/* <StyledTableCell>
                          {item.visits.visit_latitude &&
                          item.visits.visit_longitude
                            ? "Yes"
                            : "No"}
                        </StyledTableCell> */}
                        <StyledTableCell>
                          {parseFloat(item.visits.distance).toFixed(2)}
                        </StyledTableCell>{" "}
                        <StyledTableCell>
                          {formatDate(item.visits.created_at)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {formatTime(item.visits.created_at)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.visits.amount ? item.visits.amount : "No"}
                        </StyledTableCell>{" "}
                        <StyledTableCell>
                          {item.visits.order_recipt}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.image &&
                            item.image.startsWith(`${imageUrl}`) && (
                              <IconButton
                                href={item.image}
                                target="_blank"
                                rel="noopener noreferrer"
                                size="small"
                              >
                                <ImageOutlinedIcon />
                              </IconButton>
                            )}
                          {item.image1 &&
                            item.image1.startsWith(`${imageUrl}`) && (
                              <IconButton
                                href={item.image1}
                                target="_blank"
                                rel="noopener noreferrer"
                                size="small"
                              >
                                <ImageOutlinedIcon />
                              </IconButton>
                            )}
                          {item.image2 &&
                            item.image2.startsWith(`${imageUrl}`) && (
                              <IconButton
                                href={item.image2}
                                target="_blank"
                                rel="noopener noreferrer"
                                size="small"
                              >
                                <ImageOutlinedIcon />
                              </IconButton>
                            )}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.visits.visit_latitude &&
                          item.visits.visit_longitude ? (
                            <IconButton
                              color="secondary"
                              onClick={() => handleOpenMapModal(item)}
                            >
                              <MapIcon />
                            </IconButton>
                          ) : null}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, filteredVisitsByEmpCode.length]}
              component="div"
              count={filteredVisitsByEmpCode.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Rows per page"
            />
          </React.Fragment>
        ) : (
          <div style={{ padding: "20px" }}>No Visits available</div>
        )}
      </div>{" "}
      <Dialog open={openMapModal} onClose={handleCloseMapModal}>
        <DialogTitle>Location on Map</DialogTitle>
        <DialogContent>
          <MapContainer
            center={[mapLocation.latitude, mapLocation.longitude]}
            zoom={13}
            style={{ height: "400px", width: "400px" }}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker
              position={[mapLocation.latitude, mapLocation.longitude]}
              icon={locationOnIcon}
            />
          </MapContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMapModal} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>{" "}
      <div style={{ display: downloadExcel ? "block" : "none" }}>
        {downloadExcel ? (
          <ExcelDownload
            data={excelData}
            name="visits-data"
            onsubmit={disabledownload}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default WeeklyVisits;
